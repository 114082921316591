import React from "react"
import { kebabCase } from "lodash"
import Layout from "components/layout"
import Footer from "components/footer"
import SEO from "components/seo"
import { Link, graphql } from "gatsby"

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <div className="body">
    <Layout link="blog">
      <SEO
        title={`Tags | ${title}`}
        description="My blog tags page"
        keywords="Blog,Tags"
      />

      <main id="tags">
        <h1 className="lg-heading" style={{ lineHeight: "1.7" }}>
          Tags
          <span className="text-secondary"></span>
        </h1>
        <h2 className="sm-heading">
          <div className="container content">
            <div className="columns">
              <div className="column ">
                <h3>Currently {group.length} tags</h3>
                <ul className="taglist">
                  {group.map(tag => (
                    <li key={tag.fieldValue} style={{ margin: "0.6rem" }}>
                      <Link
                        className="tag tag-link"
                        to={`/tags/${kebabCase(tag.fieldValue)}/`}
                      >
                        {tag.fieldValue} ({tag.totalCount})
                      </Link>
                    </li>
                  ))}
                </ul>
                <p>
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </h2>
      </main>
    </Layout>
    <Footer />
  </div>
)

export default TagsPage

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
